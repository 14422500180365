<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-text>
          <v-row class="ml-2">
            <v-col
                cols="12"
                class="d-flex flex-column justify-space-between align-center"
            >
                <v-img :src="persongem" width="480px"></v-img>
                <h2>Parabéns e obrigado pela participação</h2>
                <p>Sua avaliação foi concluída com sucesso.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imagem from "@/assets/images/svg/finish.svg";
export default {
  name: "modal-apresentacao",
  data: () => ({
    persongem: imagem
  }),
  methods: {
    iniciarPesquisa() {
      this.dialog = false;
      this.formulario = true;
      this.$store
        .dispatch(
          "pesquisas/atualizarStatusFormulario",
          this.dadosFormulario.formulario.uuid
        )
        .then((resp) => {
          console.log(resp);
          if (resp != null || undefined) {
            this.dadosFormulario.formulario = resp;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 5000,
            text: err.data.message,
          });
        });
    },
  },
  computed: {
    pesquisa: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExterna"];
      },
    },
    dadosFormulario: {
      get() {
        return this.$store.getters["pesquisas/getDadosPesquisaExterna"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setDadosPesquisaExterna", value);
      },
    },
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaFinalizadaDialogExterna"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaFinalizadaDialogExterna", value);
      },
    },
    formulario: {
      get() {
        return this.$store.getters["pesquisas/getHabilitarFormulario"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setHabilitarFormulario", value);
      },
    },
  },
};
</script>

<style>
    svg#freepik_stories-self-confidence:not(.animated) .animable {
      opacity: 0;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-4--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-3--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-2--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-1--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }
      10% {
        transform: scale(1.1);
      }
      30% {
        transform: scale(1);
      }
      40% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      60% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
</style>
